import React, { useState } from "react";

import { useFormik } from "formik";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postData } from "../../utils/axiosHelper";

const NewsletterWidget = ({ variant = "default", spacer = false }) => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const validReturnStates = ["unsubscribed", "active", "pending"];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email:
        process.env.NODE_ENV === "development" ? "newsletter@asdfer.de" : "",
    },
    onSubmit: (values) => subscribe(values),
  });

  const subscribe = (values) => {
    navigate(`/newsletter/pending`);
    return;

    setDisabled(true);
    const brot = toast.loading(t("widgets.toast.processing"));

    postData("newsletter/subscriptions/", values)
      .then(async (response) => {
        const status = response?.data?.status || "unknown";
        if (validReturnStates.includes(status)) {
          navigate(`/newsletter/${status}`);
          toast.update(brot, {
            render: t("public.newsletter.subscribed"),
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          formik.setErrors({
            fields: {
              email: t("public.newsletter.unexpected-status-returned"),
            },
          });
        }
      })
      .catch((error) => {
        formik.setErrors({ fields: error.response.data });
        toast.update(brot, {
          render: `${t("widgets.toast.error")} ${error.response.status}`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  return (
    <>
      <form>
        <p className="h5">{t("public.newsletter.subscribe-headline")}</p>
        <p>{t("public.newsletter.subscribe-reason")}</p>
        <div className="d-flex w-100 gap-2">
          <Form.Group className="mb-0 w-100">
            <label htmlFor="newsletter1" className="visually-hidden">
              {t("public.newsletter.email-label")}
            </label>
            <Form.Control
              autoComplete="off"
              disabled={disabled}
              isInvalid={formik.errors?.fields?.email}
              name="email"
              onChange={formik.handleChange}
              placeholder={t("public.newsletter.email-label")}
              type="email"
              value={formik.values.email}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors?.fields?.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            disabled={disabled}
            className="text-nowrap"
            variant="primary"
            onClick={formik.submitForm}
          >
            {t("public.newsletter.subscribe-btn")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default NewsletterWidget;
